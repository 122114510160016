<template ontouchstart="">
    <div id="loading" v-if="is_loading">
        <!--<div class="spinner"></div>-->
    </div>
    <div class="maintenance_view" v-if="is_maintenance">
        <h1>現在メンテナンス中です</h1>
        <p class="message">
            {{ maintenance_mesage }}
        </p>
    </div>
    <div class="maintenance_view" v-if="is_frozen">
        <h1>このユーザーは凍結されています</h1>
    </div>
    <div class="maintenance_view" v-if="is_delete">
        <h1>このユーザーは削除されています</h1>
    </div>
    <router-view v-if="!is_maintenance && !is_frozen && !is_delete" ref="routerView" :footer_height="footerHeight" :country="country" :language_footer="language" :currency_footer="currency" />
    <div id="script_wrap"></div>
    <div id="cookie_agree" v-if="is_cookie_agree">
        <p>当サイトでは、サイトの利便性向上のため、クッキー(Cookie)を使用しています。 サイトのクッキー(Cookie)の使用に関しては、「プライバシーポリシー」をお読みください。クッキー(Cookie)とは？</p>
        <button @click="cookie_agree()">OK</button>
    </div>
</template>

<style>
/* ファイルパスに従って@importntの後にファイルパスを書き込む */
@import './assets/css/style.css';
</style>

<script>
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/functions';
const db = firebase.firestore();
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import { useHistoryState, onBackupState } from 'vue-history-state';

import router from './router';
dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);
dayjs.extend(isBetween);
const functions = firebase.app().functions('asia-northeast1');
let verify = functions.httpsCallable('verify');
const update_customer = functions.httpsCallable('update_customer');

// ページ内の高さ
const vh = window.innerHeight;

export default {
    router,
    name: 'app',
    data: function () {
        return {
            is_loading: true,
            is_footer: true,
            is_login: false,
            is_maintenance: false,
            is_frozen: false,
            is_delete: false,
            is_cookie_agree: false,
            footerHeight: '',
            country: '',
            currency: '',
            language: '',
            uid: '',
            maintenance_mesage: '',
        };
    },
    watch: {
        $route: {
            immediate: true,
            handler() {
                console.log('コンポーネントが変更されました:', this.$refs.routerView);

                firebase.auth().onAuthStateChanged(async (user) => {
                    if (user) {
                        let uid = user.uid;
                        let frozen_flag = await db.collection('frozen_users').doc(uid).get();
                        let delete_flag = await db.collection('delete_users').doc(uid).get();

                        if (frozen_flag.exists == true) {
                            firebase
                                .auth()
                                .signOut()
                                .then(() => {
                                    this.is_frozen = true;
                                });
                        }

                        if (delete_flag.exists == true) {
                            firebase
                                .auth()
                                .signOut()
                                .then(() => {
                                    this.is_delete = true;
                                });
                        }
                    }
                });

                db.collection('system_setteings')
                    .doc('maintenance')
                    .get()
                    .then(async (doc) => {
                        if (doc.data().active == true) {
                            this.is_maintenance = true;
                            this.maintenance_mesage = doc.data().message;
                            this.is_loading = false;
                        }
                    });

                this.is_loading = false;
                let route = this.$route;
                this.setMeta(route);
                if (this.$route.name == 'success_send_point') {
                    this.is_footer = false;
                } else if (this.$route.name == 'success_buy_rank') {
                    this.is_footer = false;
                } else if (this.$route.name == 'login') {
                    this.is_footer = false;
                } else if (this.$route.name == 'signup') {
                    this.is_footer = false;
                } else {
                    this.is_footer = true;
                    let client_h = document.getElementById('footer').clientHeight;
                    this.footerHeight = client_h;
                    //console.log(this.footerHeight);
                }
            },
        },
    },
    async mounted() {
        if (document.cookie.split('; ').find((row) => row.startsWith('last_date')) != undefined) {
            // 2つの日時を生成
            const date1 = dayjs(document.cookie.split('; ').find((row) => row.startsWith('last_date')));
            const date2 = dayjs(new Date());

            // 日時の差分を取得 (単位: hours)
            const hoursDiff = date2.diff(date1, 'minutes');

            // 1時間差があるかどうかを確認して処理を実行
            if (Math.abs(hoursDiff) >= 30) {
                // 1時間差がある場合の処理
                location.reload();
            } else {
                // 1時間差がない場合の処理
            }
        }

        document.cookie = `last_date=${new Date()}`;

        const authenticated = document.cookie.includes('authenticated');
        console.log(authenticated);
        if (authenticated != true) {
            window.location.href = location.protocol + '//' + location.host + '/auth';
        }

        this.$route;

        if (!this.is_maintenance) {
            firebase.auth().onAuthStateChanged(async (user) => {
                console.log(user);
                let nick_name = await db
                    .collection('users_public')
                    .doc(user.uid)
                    .get()
                    .then((doc) => {
                        return doc.data().nick_name;
                    });

                const badge_flag = await db
                    .collection('users_private')
                    .doc(user.uid)
                    .get()
                    .then((doc) => {
                        let flag = doc.data()?.change_stripe;
                        if (flag == true && user.emailVerified == true) {
                            update_customer({
                                cus_id: doc.data().stripe_customer_id,
                                name: nick_name,
                            });
                        }

                        return doc.data()?.create_badge;
                    });

                console.log('本人認証', user.emailVerified);

                if (user.emailVerified == true && badge_flag == false) {
                    verify();
                } else {
                    console.log('認証済み');
                }

                if (user) {
                    this.is_login = true;
                    this.uid = user.uid;

                    let first_login = await db
                        .collection('users_public')
                        .doc(this.uid)
                        .get()
                        .then((doc) => {
                            return doc.data()?.first_login;
                        });

                    let start_fanpo = await db
                        .collection('users_public')
                        .doc(this.uid)
                        .get()
                        .then((doc) => {
                            return doc.data()?.setup?.start_fanpo;
                        });

                    if (location.pathname != '/start_fanpo' && first_login == false && user.emailVerified) {
                        console.log(first_login);
                        console.log('Redirecting to start_fanpo');
                        if (start_fanpo != true) {
                            window.location.href = 'http://' + location.host + '/start_fanpo';
                        }
                    }

                    const fanpo_language = document.cookie.split('; ').find((row) => row.startsWith('fanpo_language'));
                    const cookie_agree = document.cookie.split('; ').find((row) => row.startsWith('cookie_agree'));

                    if (cookie_agree == undefined) {
                        this.is_cookie_agree = true;
                    }

                    if (fanpo_language == undefined) {
                        fetch('https://ipapi.co/json/')
                            .then((response) => {
                                return response.json();
                            })
                            .then((data) => {
                                console.log(data);
                                const region = data.country;
                                this.country = region;
                                if (this.country == 'JP') {
                                    document.cookie = 'fanpo_language=japanese';
                                    document.cookie = 'fanpo_currency=jpy';

                                    db.collection('users_public').doc(this.uid).set(
                                        {
                                            language: 'japanese',
                                            currency: 'jpy',
                                        },
                                        { merge: true }
                                    );
                                } else {
                                    document.cookie = 'fanpo_language=en';
                                    document.cookie = 'fanpo_currency=usd';

                                    db.collection('users_public').doc(this.uid).set(
                                        {
                                            language: 'en',
                                            currency: 'usd',
                                        },
                                        { merge: true }
                                    );
                                }
                                const fanpo_language = document.cookie
                                    .split('; ')
                                    .find((row) => row.startsWith('fanpo_language'))
                                    .split('=')[1];
                                console.log(fanpo_language);

                                const fanpo_currency = document.cookie
                                    .split('; ')
                                    .find((row) => row.startsWith('fanpo_currency'))
                                    .split('=')[1];
                                console.log(fanpo_currency);
                            });
                    } else {
                        const fanpo_language = document.cookie
                            .split('; ')
                            .find((row) => row.startsWith('fanpo_language'))
                            .split('=')[1];

                        const fanpo_currency = document.cookie
                            .split('; ')
                            .find((row) => row.startsWith('fanpo_currency'))
                            .split('=')[1];

                        db.collection('users_public').doc(this.uid).set(
                            {
                                language: fanpo_language,
                                currency: fanpo_currency,
                            },
                            { merge: true }
                        );
                    }
                }
            });
        }

        /*window.addEventListener('pageshow', () => {
            console.log(window.performance.navigation.type);
            if (window.performance.navigation.type == 2) location.reload();
        });*/
    },
    methods: {
        setMeta(route) {
            // タイトルを設定
            if (route.meta.title) {
                let setTitle = route.meta.title;
                document.title = setTitle;
            }
            // ディスクリプションを設定
            if (route.meta.desc) {
                let setDesc = route.meta.desc;
                document.querySelector('meta[name="description"]').setAttribute('content', setDesc);
            }
        },
        sign_out() {
            this.is_loading = true;
            firebase
                .auth()
                .signOut()
                .then(() => {
                    window.location.href = 'http://' + location.host + '/login';
                })
                .catch((error) => {
                    this.is_loading = false;
                });
        },
        select_currency() {
            document.cookie = `fanpo_currency=${this.currency}`;
            this.$emit('select_currency_footer');
        },
        select_language() {
            document.cookie = `fanpo_language=${this.language}`;
            this.$emit('select_language_footer');
        },
        cookie_agree() {
            document.cookie = 'cookie_agree=true';
            this.is_cookie_agree = false;
        },
    },
};
</script>
