<template>
    <!--<div id="loading" v-if="is_loading">
        <div class="spinner"></div>
    </div>-->
    <header style="position: sticy !important; margin-bottom: 0 !important">
        <router-link to="/">
            <svg class="hrader_logo left_style" width="80" height="14" viewBox="0 0 80 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M0.583533 0.194444H11.4761C11.6346 0.194444 11.7715 0.246296 11.8868 0.35C12.002 0.453704 12.0597 0.576852 12.0597 0.719444V3.675C12.0597 3.81759 12.002 3.94074 11.8868 4.04444C11.7715 4.14815 11.6346 4.2 11.4761 4.2H5.29502V5.63889H11.0439C11.2024 5.63889 11.3393 5.69074 11.4545 5.79444C11.5698 5.89815 11.6274 6.0213 11.6274 6.16389V9.1C11.6274 9.24259 11.5698 9.36574 11.4545 9.46944C11.3393 9.57315 11.2024 9.625 11.0439 9.625H5.29502V13.2806C5.29502 13.4231 5.23738 13.5463 5.12212 13.65C5.00685 13.7537 4.86997 13.8056 4.71148 13.8056H0.583533C0.425042 13.8056 0.288164 13.7537 0.172899 13.65C0.0576329 13.5463 0 13.4231 0 13.2806V0.719444C0 0.576852 0.0576329 0.453704 0.172899 0.35C0.288164 0.246296 0.425042 0.194444 0.583533 0.194444Z"
                    fill="#000000"
                />
                <path
                    d="M19.7123 0.194444H24.4022C24.9209 0.194444 25.245 0.408333 25.3747 0.836111L30.1943 13.2417C30.2087 13.2806 30.2159 13.3454 30.2159 13.4361C30.2159 13.5139 30.1654 13.5981 30.0646 13.6889C29.9781 13.7667 29.8701 13.8056 29.7404 13.8056H25.7421C25.3099 13.8056 25.0289 13.6435 24.8992 13.3194L24.3805 12.0361H19.7339L19.2152 13.3194C19.0855 13.6435 18.8046 13.8056 18.3723 13.8056H14.374C14.2444 13.8056 14.1291 13.7667 14.0282 13.6889C13.9418 13.5981 13.8986 13.5139 13.8986 13.4361C13.8986 13.3454 13.9058 13.2806 13.9202 13.2417L18.7397 0.836111C18.8694 0.408333 19.1936 0.194444 19.7123 0.194444ZM20.8361 8.24444H23.2783L22.0464 4.62778L20.8361 8.24444Z"
                    fill="#000000"
                />
                <path
                    d="M41.717 0.194444H45.6288C45.7873 0.194444 45.9242 0.246296 46.0395 0.35C46.1547 0.453704 46.2124 0.576852 46.2124 0.719444V13.2806C46.2124 13.4231 46.1547 13.5463 46.0395 13.65C45.9242 13.7537 45.7873 13.8056 45.6288 13.8056H42.2141C41.8395 13.8056 41.5441 13.6565 41.328 13.3583L37.6755 8.4V13.2806C37.6755 13.4231 37.6179 13.5463 37.5026 13.65C37.3873 13.7537 37.2505 13.8056 37.092 13.8056H33.1801C33.0216 13.8056 32.8848 13.7537 32.7695 13.65C32.6542 13.5463 32.5966 13.4231 32.5966 13.2806V0.719444C32.5966 0.576852 32.6542 0.453704 32.7695 0.35C32.8848 0.246296 33.0216 0.194444 33.1801 0.194444H36.5949C36.9983 0.194444 37.2937 0.343518 37.481 0.641666L41.1335 6.02778V0.719444C41.1335 0.576852 41.1911 0.453704 41.3064 0.35C41.4216 0.246296 41.5585 0.194444 41.717 0.194444Z"
                    fill="#000000"
                />
                <path
                    d="M50.2488 0.194444H56.6893C58.764 0.194444 60.385 0.596296 61.552 1.4C62.7191 2.2037 63.3026 3.38981 63.3026 4.95833C63.3026 6.52685 62.7191 7.68704 61.552 8.43889C60.3994 9.19074 58.7785 9.56667 56.6893 9.56667H55.2845V13.2806C55.2845 13.4231 55.2268 13.5463 55.1116 13.65C54.9963 13.7537 54.8594 13.8056 54.7009 13.8056H50.2488C50.0903 13.8056 49.9534 13.7537 49.8382 13.65C49.7229 13.5463 49.6653 13.4231 49.6653 13.2806V0.719444C49.6653 0.576852 49.7229 0.453704 49.8382 0.35C49.9534 0.246296 50.0903 0.194444 50.2488 0.194444ZM55.1764 3.81111V5.96944H56.6893C56.9486 5.96944 57.1791 5.88519 57.3809 5.71667C57.5826 5.53519 57.6834 5.28241 57.6834 4.95833C57.6834 4.63426 57.6042 4.36204 57.4457 4.14167C57.2872 3.9213 57.0351 3.81111 56.6893 3.81111H55.1764Z"
                    fill="#000000"
                />
                <path
                    d="M67.5297 1.43889C68.8553 0.47963 70.6059 0 72.7815 0C74.9571 0 76.7005 0.486111 78.0117 1.45833C79.3372 2.41759 80 3.77222 80 5.52222V8.55555C80 10.3315 79.3444 11.6861 78.0333 12.6194C76.7365 13.5398 74.9859 14 72.7815 14C70.577 14 68.8192 13.5398 67.5081 12.6194C66.2113 11.6861 65.563 10.3315 65.563 8.55555V5.52222C65.563 3.75926 66.2186 2.39815 67.5297 1.43889ZM71.6144 9.81944C71.9026 10.0787 72.2916 10.2083 72.7815 10.2083C73.2714 10.2083 73.6604 10.0787 73.9486 9.81944C74.2367 9.56018 74.3808 9.16481 74.3808 8.63333V5.36667C74.3808 4.84815 74.2367 4.45926 73.9486 4.2C73.6604 3.92778 73.2714 3.79167 72.7815 3.79167C72.2916 3.79167 71.9026 3.92778 71.6144 4.2C71.3263 4.45926 71.1822 4.84815 71.1822 5.36667V8.63333C71.1822 9.16481 71.3263 9.56018 71.6144 9.81944Z"
                    fill="#000000"
                />
            </svg>
        </router-link>

        <v-btn @click="open_menu()" icon v-if="!is_notifications_show && login_user">
            <div class="mypage_link">
                <img id="image" src="" />
            </div>
        </v-btn>

        <div class="login_button" v-if="logout_user" @click="open_menu()" v-bind:style="{ color: base_text_color }">ログイン・新規登録</div>

        <v-btn @click="open_notifications()" v-if="!is_notifications_show && login_user">
            <div class="bell">
                <svg width="30" height="30" viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M15 26C16.241 26 17.2564 24.9846 17.2564 23.7436H12.7436C12.7436 24.9846 13.759 26 15 26ZM21.7692 19.2308V13.5897C21.7692 10.1262 19.9303 7.22667 16.6923 6.45949V5.69231C16.6923 4.7559 15.9364 4 15 4C14.0636 4 13.3077 4.7559 13.3077 5.69231V6.45949C10.081 7.22667 8.23078 10.1149 8.23078 13.5897V19.2308L5.97437 21.4872V22.6154H24.0256V21.4872L21.7692 19.2308ZM19.5128 20.359H10.4872V13.5897C10.4872 10.7918 12.1908 8.51282 15 8.51282C17.8092 8.51282 19.5128 10.7918 19.5128 13.5897V20.359Z"
                        :fill="base_text_color"
                    />
                </svg>
            </div>
        </v-btn>

        <!--<v-btn @click="open_menu()" v-if="!is_menu_show && !is_notifications_show">
            <div class="burger_menu">
                <svg width="30" height="30" viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg">
                    <rect x="6" y="7.5" width="18" height="3" :fill="base_text_color" />
                    <rect x="6" y="13.5" width="18" height="3" :fill="base_text_color" />
                    <rect x="6" y="19.5" width="18" height="3" :fill="base_text_color" />
                </svg>
            </div>
        </v-btn>

        <v-btn @click="close_menu()" v-if="is_menu_show">
            <div class="burger_menu">
                <svg width="30" height="30" viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_5413_2291)">
                        <path d="M23.75 8.0125L21.9875 6.25L15 13.2375L8.0125 6.25L6.25 8.0125L13.2375 15L6.25 21.9875L8.0125 23.75L15 16.7625L21.9875 23.75L23.75 21.9875L16.7625 15L23.75 8.0125Z" :fill="base_text_color" />
                    </g>
                    <defs>
                        <clipPath id="clip0_5413_2291">
                            <rect width="30" height="30" fill="white" />
                        </clipPath>
                    </defs>
                </svg>
            </div>
        </v-btn>
        -->

        <v-btn @click="close_notifications()" v-if="is_notifications_show">
            <div class="burger_menu">
                <svg width="30" height="30" viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_5413_2291)">
                        <path d="M23.75 8.0125L21.9875 6.25L15 13.2375L8.0125 6.25L6.25 8.0125L13.2375 15L6.25 21.9875L8.0125 23.75L15 16.7625L21.9875 23.75L23.75 21.9875L16.7625 15L23.75 8.0125Z" :fill="base_text_color" />
                    </g>
                    <defs>
                        <clipPath id="clip0_5413_2291">
                            <rect width="30" height="30" fill="white" />
                        </clipPath>
                    </defs>
                </svg>
            </div>
        </v-btn>

        <!--<div class="search_button" @click="move_search()">
            検索する
            <img class="search_logo" src="@/assets/img/search_logo.png" />
        </div>-->

        <div @click="handleOutsideClick">
            <div class="menu" v-if="is_menu_show">
                <div class="background" @click="close_menu()"></div>
                <div class="list_wrap">
                    <div class="list" v-if="logout_user && language == 'japanese'" @click="move_search()">検索</div>
                    <div class="list" v-if="logout_user && language == 'en'" @click="move_search()">Search</div>
                    <router-link to="/signup" v-if="language == 'japanese'">
                        <div class="list" v-if="logout_user">新規登録</div>
                    </router-link>
                    <router-link to="/signup" v-if="language == 'en'">
                        <div class="list" v-if="logout_user">Signup</div>
                    </router-link>
                    <router-link to="/login" v-if="language == 'japanese'">
                        <div class="list" v-if="logout_user">ログイン</div>
                    </router-link>
                    <router-link to="/login" v-if="language == 'en'">
                        <div class="list" v-if="logout_user">Login</div>
                    </router-link>
                    <div class="list" v-if="logout_user && language == 'japanese'" @click="move_top()">FANPOとは？</div>
                    <div class="list" v-if="logout_user && language == 'en'" @click="move_top()">What is FANPO?</div>
                    <div class="list" v-if="login_user && language == 'japanese'" @click="move_user()">プロフィール</div>
                    <div class="list" v-if="login_user && language == 'en'" @click="move_user()">Profile</div>
                    <router-link to="/mypage" v-if="language == 'japanese'">
                        <div class="list" v-if="login_user">設定</div>
                    </router-link>
                    <router-link to="/mypage" v-if="language == 'en'">
                        <div class="list" v-if="login_user">Dashboard</div>
                    </router-link>
                    <div class="list" v-if="login_user && language == 'japanese'" @click="move_search()">検索</div>
                    <div class="list" v-if="login_user && language == 'en'" @click="move_search()">Search</div>
                    <div class="list_language" v-if="language == 'japanese'" @click="open_select_language()">
                        <p class="title">言語</p>
                        <p class="lang">日本語</p>
                    </div>
                    <div class="list_language" v-if="language == 'en'" @click="open_select_language()">
                        <p class="title">Language</p>
                        <p class="lang">English</p>
                    </div>
                    <div class="list_language" v-if="language == 'japanese'" @click="open_select_currency()">
                        <p class="title">通貨</p>
                        <p class="lang" v-if="currency == 'jpy'">JPY 円</p>
                        <p class="lang" v-if="currency == 'usd'">USD $</p>
                    </div>
                    <div class="list_language" v-if="language == 'en'" @click="open_select_currency()">
                        <p class="title">Currency</p>
                        <p class="lang" v-if="currency == 'jpy'">JPY 円</p>
                        <p class="lang" v-if="currency == 'usd'">USD $</p>
                    </div>

                    <div class="list red" v-if="login_user && language == 'japanese'" @click="logout()">ログアウト</div>
                    <div class="list red" v-if="login_user && language == 'en'" @click="logout()">Logout</div>
                </div>

                <!--<div class="heading" v-if="language == 'japanese'">言語と通貨</div>
                <div class="heading" v-if="language == 'en'">Language and Currency</div>
                <div class="select_wrap">
                    <select class="language" v-model="language" @change="select_language">
                        <option value="japanese">日本語</option>
                        <option value="en">English</option>
                    </select>
                    <select class="currency" v-model="currency" @change="select_currency">
                        <option value="jpy">JPY 円</option>
                        <option value="usd">USD $</option>
                    </select>
                </div>
                -->
            </div>
        </div>

        <div class="select_wrap" v-if="is_select_language">
            <div class="background" @click="close_select_language()"></div>
            <div class="select_content_wrap">
                <div class="select_content">
                    <div class="select_title" v-if="language == 'japanese'">言語設定</div>
                    <div class="select_title" v-if="language == 'en'">Language Settings</div>
                    <select class="selecter" v-model="language" @change="select_language">
                        <option value="japanese">日本語</option>
                        <option value="en">English</option>
                    </select>
                </div>
                <div class="close_button" v-if="language == 'japanese'" @click="close_select_language()">閉じる</div>
                <div class="close_button" v-if="language == 'en'" @click="close_select_language()">Close</div>
            </div>
        </div>

        <div class="select_wrap" v-if="is_select_currency">
            <div class="background" @click="close_select_currency()"></div>
            <div class="select_content_wrap">
                <div class="select_content">
                    <div class="select_title" v-if="language == 'japanese'">通貨設定</div>
                    <div class="select_title" v-if="language == 'en'">Currency Settings</div>
                    <select class="selecter" v-model="currency" @change="select_currency">
                        <option value="jpy">JPY 円</option>
                        <option value="usd">USD $</option>
                    </select>
                </div>
                <div class="close_button" v-if="language == 'japanese'" @click="close_select_currency()">閉じる</div>
                <div class="close_button" v-if="language == 'en'" @click="close_select_currency()">Close</div>
            </div>
        </div>
        <div class="close_menu" v-if="menu_show" @click="menu_show = !menu_show"></div>

        <!--通知モジュール-->
        <div class="notifications" v-if="is_notifications_show">
            <div class="select_list" v-if="is_notifications">
                <div class="active left">通知</div>
                <div class="false right" @click="is_notifications = !is_notifications">運営からのお知らせ</div>
            </div>
            <ul id="notifications_list" v-show="is_none_item && is_notifications">
                <li class="none_history">通知がありません</li>
            </ul>

            <ul id="notifications_list" v-show="!is_none_item && is_notifications">
                <li v-for="notification in sortedNotifications" :key="notification.date" v-cloak @click="move_contents(notification.link)">
                    <div class="info_wrap">
                        <img id="image" :src="notification.img_url" :style="{ display: notification.display }" />
                        <p v-html="notification.contents"></p>
                    </div>
                    <img class="search_logo" src="@/assets/img/arrow_right.png" />
                </li>
            </ul>

            <ul id="pagination" v-show="!is_none_item && is_notifications" style="margin-top: 40px">
                <li v-for="pagination in filteredPaginations" :key="pagination.date" :class="{ active: now_page_num === pagination.count }" @click="more_list(pagination)" v-cloak>
                    {{ pagination.count }}
                </li>
            </ul>

            <div class="select_list" v-show="!is_notifications">
                <div class="false left" @click="is_notifications = !is_notifications">通知</div>
                <div class="active right">運営からのお知らせ</div>
            </div>
            <ul id="notifications_list" v-show="is_none_item && !is_notifications">
                <li class="none_history">通知がありません</li>
            </ul>

            <ul id="operations_notifications_list" v-show="!is_none_item && !is_notifications">
                <li v-for="notification in sortedNotice_from_Management" :key="notification.date" v-cloak @click="move_contents(notification.link)">
                    <div class="info_wrap">
                        <p class="title">{{ notification.title }}</p>
                        <p class="date">{{ notification.date }}</p>
                    </div>
                    <img class="search_logo" src="@/assets/img/arrow_right.png" />
                </li>
            </ul>

            <ul id="pagination" v-show="!is_none_item && !is_notifications" style="margin-top: 40px">
                <li v-for="pagination in sortedManagementPaginations" :key="pagination.date" :class="{ active: now_page_num === pagination.count }" @click="more_list(pagination)" v-cloak>
                    {{ pagination.count }}
                </li>
            </ul>
        </div>
    </header>
    <div class="top_mainvisual" :style="{ height: menu_height }">
        <img class="background" src="@/assets/img/top_mainvisual.png" />
        <img class="component" src="@/assets/img/top_mainvisula_photo01.png" />
    </div>

    <div class="home_content">
        <div class="create_profile_button" v-if="logout_user" @click="move_signup()">プロフィールを無料作成</div>
        <div class="create_profile_button" v-if="!logout_user" @click="move_user()">マイページへ</div>
        <p class="login_text" v-if="logout_user">アカウントをお持ちの方はこちらから<span @click="move_login()">ログイン</span></p>

        <div class="title_content">
            <p class="small_text">LINK IN BIO</p>
            <div class="main_title">
                複数リンクをひとつに<br />
                まとめる無料ツール
            </div>
            <div class="about_text">あなたのSNSやURLを一つのリンクに集約しましょう！FANPOは無料のリンクまとめサービスです。スマホで簡単に作成できます。</div>
            <img class="main_photo" src="@/assets/img/link_in_bio.png" />
        </div>

        <div class="title_content">
            <p class="small_text">POINT</p>
            <div class="main_title">
                プロフィールページで<br />
                投げ銭できる
            </div>
            <div class="about_text">プロフィールページ上で、ファンが投げ銭を送ることができます。</div>
            <img class="main_photo" src="@/assets/img/point.png" />
        </div>

        <div class="title_content">
            <p class="small_text">SUBSCRIPTION</p>
            <div class="main_title">
                サブスクで推しを<br />
                サポートしよう
            </div>
            <div class="about_text">毎月定額でサポートを行いたいファンが、 月額で投げ銭を送ることができます。</div>
            <img class="main_photo" src="@/assets/img/SUBSCRIPTION.png" />
        </div>

        <div class="title_content">
            <p class="small_text">RANKING</p>
            <div class="main_title">ファンランキング機能</div>
            <div class="about_text">ファンのランキング機能など、エンゲージメントを高める機能が無料で使えます。</div>
            <img class="main_photo" src="@/assets/img/RANKING.png" />
        </div>

        <div class="title_content">
            <p class="small_text">DESIGN</p>
            <div class="main_title">
                デザインを自由にカス<br />
                タマイズしよう
            </div>
            <div class="about_text">プロフィールページは、カスタマイズ可能！豊富なテンプレートに加えて、あなたの思い通りのデザインにすることができます。</div>
            <img class="main_photo" src="@/assets/img/DESIGN.png" />
        </div>

        <div class="bottom_content">
            <div class="bottom_title">
                高い収益性であなたの<br />
                活動をサポートします
            </div>
            <div class="about_card">
                <div class="card_title">ドル決済対応</div>
                <div class="card_about">
                    海外からの投げ銭も可能です。<br />
                    グローバルでの活動を<br />
                    サポートします。
                </div>
            </div>

            <div class="about_card">
                <div class="card_title">安い手数料</div>
                <div class="card_about">
                    システム利用手数料は25%。<br />
                    業界トップクラスの安さです。
                </div>
            </div>

            <div class="about_card">
                <div class="card_title">すばやい振込</div>
                <div class="card_about">
                    自由なタイミングで、<br />
                    振込申請ができます。
                </div>
            </div>

            <div class="bottom_title">
                コンテンツ不要で<br />
                マネタイズ可能
            </div>
            <div class="about_card" style="margin-bottom: 0">
                <div class="card_title">本業の活動に集中できます</div>
                <div class="card_about" style="text-align: left">既存のファンクラブサービスのように、専用のコンテンツを用意しなくても、収益化が可能です。ファンの為の創作や活動に、集中することができます。</div>
            </div>
        </div>
    </div>
    <footer id="footer" ref="footer">
        <img class="logo" src="@/assets/img/powerd_by_fanpo_black.svg" />
        <router-link class="signup_button" to="/signup"> アカウントを作成する </router-link>
        <div class="login_text" v-show="!is_login">
            アカウントをお持ちの方<br />
            <router-link class="link" to="/login"> ログインする</router-link>
        </div>

        <div class="select_section">
            <div class="language_box">
                <select v-model="language" @change="select_language">
                    <option value="japanese">日本語</option>
                    <option value="en">English</option>
                </select>
                <span>言語</span>
                <p class="language" v-if="language == 'japanese'">日本語</p>
                <p class="language" v-if="language == 'en'">英語</p>
            </div>
            <div class="currency_box">
                <span class="bold">通貨</span>
                <select class="currency" v-model="currency" @change="select_currency">
                    <option value="jpy">JPY 円</option>
                    <option value="usd">USD $</option>
                </select>
            </div>
        </div>

        <div class="login_text pointer" @click="sign_out()" v-show="is_login">サインアウト</div>
        <div class="credit">Copyright © FANPO, Inc. All rights reserved.</div>
    </footer>
</template>

<script>
// @ is an alias to /src
import 'firebase/compat/auth';
import 'firebase/compat/storage';
import 'firebase/compat/firestore';
import firebase from 'firebase/compat/app';
firebase.initializeApp({
    apiKey: process.env.VUE_APP_API_KEY,
    authDomain: process.env.VUE_APP_AUTH_DOMAIN,
    databaseURL: process.env.VUE_APP_DATABASE_URL,
    projectId: process.env.VUE_APP_PROJECT_ID,
    storageBucket: process.env.VUE_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.VUE_APP_MESSAGING_SENDER_ID,
    appId: process.env.VUE_APP_ID,
});

const db = firebase.firestore();

export default {
    computed: {
        sortedInfluencers() {
            return this.influencers.slice().sort((a, b) => {
                return b.user_name - a.user_name;
            });
        },
        sortedNotifications() {
            return this.notifications.slice().sort((a, b) => {
                return b.seconds - a.seconds;
            });
        },
        sortedPaginations() {
            return this.paginations.slice().sort((a, b) => {
                return a.count - b.count;
            });
        },
        sortedNotice_from_Management() {
            return this.Notice_from_Management.slice().sort((a, b) => {
                return b.seconds - a.seconds;
            });
        },
        sortedManagementPaginations() {
            return this.Management_paginations.slice().sort((a, b) => {
                return a.count - b.count;
            });
        },
        sortedNotice_from_special_mentions() {
            return this.special_mentions.slice().sort((a, b) => {
                return b.seconds - a.seconds;
            });
        },
        filteredPaginations() {
            return this.sortedPaginations.slice(0, 5);
        },
    },
    data: function () {
        return {
            login_user: false,
            logout_user: false,
            is_menu_show: false,
            is_loading: true,
            profileSrc_header: '',

            is_notifications_show: false,
            is_notifications: true,
            last_doc_Management: null,

            currency: '',
            language: '',

            creater_name: '',
            year_id: '',
            month_id: '',
            notifications: [],
            Notice_from_Management: [],
            is_none_item: true,
            Management_paginations: [],
            paginations: [],
            last_doc: null, // 前回取得した最後のドキュメント
            limit: 10, // 1回の取得件数
            now_page_num: 1,

            img_url: '',

            is_none_special_mentions: false,
            special_mentions: [],
            last_doc_special_mentions: null,

            menu_height: '570px',

            is_select_language: false,
            is_select_currency: false,
        };
    },
    async mounted() {
        const fanpo_language = document.cookie
            .split('; ')
            .find((row) => row.startsWith('fanpo_language'))
            .split('=')[1];

        const fanpo_currency = document.cookie
            .split('; ')
            .find((row) => row.startsWith('fanpo_currency'))
            .split('=')[1];

        this.language = fanpo_language;

        this.currency = fanpo_currency;
        await firebase.auth().onAuthStateChanged(async (user) => {
            if (user) {
                this.login_user = true;
                this.logout_user = false;

                let uid = user.uid;

                // ユーザー公開情報取得
                const userPublicRef = db.collection('users_public').doc(uid);
                const storageRef = firebase.storage().ref();

                await Promise.all([userPublicRef.get(), storageRef.child(`profile_images/${uid}/profile_img`).getDownloadURL()])
                    .then(async ([userPublicDoc, downloadURL]) => {
                        // ユーザー名取得
                        const nick_name = await userPublicDoc.data().nick_name;
                        this.nick_name = nick_name;

                        // ユーザーID取得
                        this.user_id = await userPublicDoc.data().original_id;

                        // プロフィール画像表示処理
                        document.getElementById('image').src = downloadURL;
                        document.getElementById('image_profile').src = downloadURL;
                        document.getElementById('image').style.display = 'block';
                    })
                    .catch(async (error) => {
                        // ユーザー名とユーザーIDを取得する
                        await userPublicRef
                            .get()
                            .then((userPublicDoc) => {
                                // ユーザー名取得
                                const nick_name = userPublicDoc.data().nick_name;
                                this.nick_name = nick_name;

                                // ユーザーID取得
                                this.user_id = userPublicDoc.data().original_id;
                            })
                            .catch((error) => {});

                        // デフォルトの画像を表示する
                        await storageRef
                            .child('no_image/noimage.png')
                            .getDownloadURL()
                            .then((downloadURL) => {
                                document.getElementById('image').src = downloadURL;
                                document.getElementById('image_profile').src = downloadURL;
                                document.getElementById('image').style.display = 'block';
                            })
                            .catch((error) => {});
                    });

                await db
                    .collection('users_public')
                    .doc(uid)
                    .get()
                    .then(async (doc) => {
                        const original_id = await doc.data().original_id;
                        this.mypage_link = location.protocol + '//' + location.host + '/user' + '?' + original_id;
                    });

                //通知取得
                const query_size = await db.collection('users_public').doc(uid).collection('notification').orderBy('date', 'desc').get();

                console.log('総サイズ', Math.ceil(query_size.size / this.limit));

                if (query_size.size > 0) {
                    this.is_none_item = false;
                }

                for (let i = 0; i < Math.ceil(query_size.size / this.limit); i++) {
                    if (this.last_doc) {
                        const querySnapshot = await db.collection('users_public').doc(uid).collection('notification').orderBy('date', 'desc').startAfter(this.last_doc).limit(this.limit).get();
                        let count = 0;

                        querySnapshot.forEach((doc) => {
                            const data = doc.data();
                            count++;

                            if (count === querySnapshot.size) {
                                this.last_doc = data.date;
                                this.paginations.push({
                                    count: i + 1,
                                    date: data.date,
                                });
                            }
                        });
                    } else {
                        const querySnapshot = await db.collection('users_public').doc(uid).collection('notification').orderBy('date', 'desc').limit(this.limit).get();
                        let count = 0;

                        querySnapshot.forEach(async (doc) => {
                            const data = doc.data();
                            count++;

                            if (count === 1) {
                                this.last_doc = data.date;
                                this.paginations.push({
                                    count: i + 1,
                                    date: data.date,
                                });
                            }

                            let type = data.type;
                            let nick_name = doc.data().nick_name;

                            console.log(data.date.seconds);

                            if (type === 'follow') {
                                let doc_uid = data.uid;
                                //プロフィール画像取得
                                const userPublicRef = db.collection('users_public').doc(doc_uid);
                                const storageRef = firebase.storage().ref();
                                await Promise.all([userPublicRef.get(), storageRef.child(`profile_images/${doc_uid}/profile_img`).getDownloadURL()])
                                    .then(async ([userPublicDoc, downloadURL]) => {
                                        // プロフィール画像表示処理
                                        this.img_url = downloadURL;
                                    })
                                    .catch(async (error) => {
                                        // デフォルトの画像を表示する
                                        await storageRef
                                            .child('no_image/noimage.png')
                                            .getDownloadURL()
                                            .then((downloadURL) => {
                                                this.img_url = downloadURL;
                                            })
                                            .catch((error) => {});
                                    });

                                await this.notifications.push({
                                    contents: `<b>${nick_name}</b>があなたを<b>フォロー</b>しました`,
                                    date: data.date,
                                    link: 'http://' + location.host + '/user?' + data.original_id,
                                    display: 'block',
                                    img_url: this.img_url,
                                    seconds: data.date.seconds,
                                });
                            } else if (type === 'fan_point') {
                                let doc_uid = data.uid;
                                //プロフィール画像取得
                                const userPublicRef = db.collection('users_public').doc(doc_uid);
                                const storageRef = firebase.storage().ref();
                                await Promise.all([userPublicRef.get(), storageRef.child(`profile_images/${doc_uid}/profile_img`).getDownloadURL()])
                                    .then(async ([userPublicDoc, downloadURL]) => {
                                        this.img_url = downloadURL;
                                    })
                                    .catch(async (error) => {
                                        // デフォルトの画像を表示する
                                        await storageRef
                                            .child('no_image/noimage.png')
                                            .getDownloadURL()
                                            .then((downloadURL) => {
                                                this.img_url = downloadURL;
                                            })
                                            .catch((error) => {});
                                    });

                                let point = doc.data().point;
                                await this.notifications.push({
                                    contents: `<b>${nick_name}</b>があなたに<b>${point}ファンポイント</b>を送信しました`,
                                    date: data.date,
                                    link: 'http://' + location.host + '/user?' + data.original_id,
                                    display: 'block',
                                    img_url: this.img_url,
                                    seconds: data.date.seconds,
                                });
                            } else if (type === 'fan_badge') {
                                let doc_uid = data.uid;
                                //プロフィール画像取得
                                const userPublicRef = db.collection('users_public').doc(doc_uid);
                                const storageRef = firebase.storage().ref();
                                await Promise.all([userPublicRef.get(), storageRef.child(`profile_images/${doc_uid}/profile_img`).getDownloadURL()])
                                    .then(async ([userPublicDoc, downloadURL]) => {
                                        this.img_url = downloadURL;
                                    })
                                    .catch(async (error) => {
                                        // デフォルトの画像を表示する
                                        await storageRef
                                            .child('no_image/noimage.png')
                                            .getDownloadURL()
                                            .then((downloadURL) => {
                                                this.img_url = downloadURL;
                                            })
                                            .catch((error) => {});
                                    });

                                let badge = doc.data().badge;
                                await this.notifications.push({
                                    contents: `<b>${nick_name}</b>があなたの<b>${badge}ファンバッジ</b>を獲得しました`,
                                    date: data.date,
                                    link: 'http://' + location.host + '/user?' + data.original_id,
                                    display: 'block',
                                    img_url: this.img_url,
                                    seconds: data.date.seconds,
                                });
                            } else if (type === 'operations') {
                                let title = doc.data().title;
                                await this.notifications.push({
                                    contents: `<b>${title}</b>`,
                                    date: data.date,
                                    link: 'http://' + location.host + '/notice?' + data.doc_id,
                                    display: 'none',
                                    seconds: data.date.seconds,
                                });
                            }
                        });
                    }
                }

                //通知取得
                let Management_query_size = await db.collection('operations_notification').orderBy('date', 'desc').get();

                if (Management_query_size.size > 0) {
                    this.is_none_item = false;
                }

                for (let i = 0; i < Math.ceil(Management_query_size.size / this.limit); i++) {
                    if (this.last_doc_Management) {
                        const querySnapshot = await db.collection('operations_notification').orderBy('date', 'desc').startAfter(this.last_doc).limit(this.limit).get();
                        let count = 0;

                        querySnapshot.forEach((doc) => {
                            const data = doc.data();
                            count++;

                            if (count === querySnapshot.size) {
                                this.last_doc = data.date;
                                this.Management_paginations.push({
                                    count: i + 1,
                                    date: data.date,
                                });
                            }
                        });
                    } else {
                        const querySnapshot = await db.collection('operations_notification').orderBy('date', 'desc').limit(this.limit).get();
                        let count = 0;

                        querySnapshot.forEach(async (doc) => {
                            const data = doc.data();
                            count++;

                            if (count === 1) {
                                this.last_doc = data.date;
                                this.Management_paginations.push({
                                    count: i + 1,
                                    date: data.date,
                                });
                            }

                            let title = doc.data().title;
                            let dt = new Date(doc.data().date.seconds * 1000);
                            let y = dt.getFullYear();
                            let m = ('00' + (dt.getMonth() + 1)).slice(-2);
                            let d = ('00' + dt.getDate()).slice(-2);

                            await this.Notice_from_Management.push({
                                title: title,
                                date: `${y}/${m}/${d}`,
                                link: 'http://' + location.host + '/notice?' + doc.id,
                                seconds: data.date.seconds,
                            });
                        });
                    }
                }

                //特別通知取得
                let special_mentions_query_size = await db.collection('operations_notification').where('special_mention.dashboard', '==', true).orderBy('date', 'desc').get();
                console.log('スペシャルメンションサイズ', special_mentions_query_size.size);

                if (special_mentions_query_size.size > 0) {
                    this.is_none_special_mentions = false;
                }

                const querySnapshot = await db.collection('operations_notification').where('special_mention.dashboard', '==', true).orderBy('date', 'desc').get();

                querySnapshot.forEach(async (doc) => {
                    const data = doc.data();

                    let title = doc.data().title;
                    await this.special_mentions.push({
                        title: `<b>${title}</b>`,
                        date: data.date,
                        link: 'http://' + location.host + '/notice?' + doc.id,
                        display: 'none',
                        seconds: data.date.seconds,
                    });
                });
            } else {
                this.login_user = false;
                this.logout_user = true;
            }
        });

        await setTimeout(() => {
            this.is_loading = false;
        }, 1500);

        await this.scroll_active();
    },
    methods: {
        open_select_language() {
            document.body.style.overflow = 'hidden';
            this.is_select_language = true;
            this.close_menu();
        },
        close_select_language() {
            this.is_select_language = false;
            this.open_menu();
        },
        open_select_currency() {
            document.body.style.overflow = 'hidden';
            this.is_select_currency = true;
            this.close_menu();
        },
        close_select_currency() {
            this.is_select_currency = false;
            this.open_menu();
        },
        move_contents(link) {
            window.location.href = link;
        },
        open_menu() {
            document.body.style.overflow = 'hidden';
            this.is_menu_show = true;
            this.header_background = 'rgba(255,255,255,1)';
        },
        close_menu() {
            document.body.style.overflow = 'auto';
            this.is_menu_show = false;
            this.header_background = 'rgba(255,255,255,0)';
        },
        open_notifications() {
            this.is_notifications_show = true;
            document.body.classList.add('scroll-lock');
        },
        close_notifications() {
            this.is_notifications_show = false;
            document.body.classList.remove('scroll-lock');
        },
        select_currency() {
            document.cookie = `fanpo_currency=${this.currency}`;
        },
        select_language() {
            document.cookie = `fanpo_language=${this.language}`;
        },
        logout() {
            firebase
                .auth()
                .signOut()
                .then(() => {
                    // Sign-out successful.
                })
                .catch((error) => {
                    // eslint-disable-line
                    // An error happened.
                });
        },
        move_search() {
            window.location.href = 'http://' + location.host + '/search';
        },
        move_user() {
            firebase.auth().onAuthStateChanged(async (user) => {
                if (user) {
                    const uid = user.uid;
                    const user_info = await db.collection('users_public').doc(uid).get();
                    const original_id = await user_info.data().original_id;
                    window.location.href = 'http://' + location.host + '/user' + '?' + original_id;
                }
            });
        },
        move_signup() {
            window.location.href = 'http://' + location.host + '/signup';
        },
        move_login() {
            window.location.href = 'http://' + location.host + '/login';
        },
        move_top() {
            window.location.href = 'http://' + location.host;
        },
        delete_admin() {
            firebase.auth().onAuthStateChanged((user) => {
                if (user) {
                    const uid = user.uid;
                }
            });
        },
        scroll_active() {
            document.body.style.overflow = 'auto';
        },
    },
};
</script>
