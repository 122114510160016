import { createRouter, createWebHistory } from 'vue-router';
import Home from '../views/Home.vue';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import dayjs from 'dayjs';
import Cookies from 'js-cookie';
import 'firebase/compat/firestore';
const db = firebase.firestore();
let user_name;

firebase.auth().onAuthStateChanged(async (user) => {
    if (user) {
        if (user.emailVerified) {
            user_name = await db
                .collection('users_public')
                .doc(user.uid)
                .get()
                .then((doc) => {
                    return doc.data().nick_name;
                });
        }
    }
});

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home,
        meta: { title: 'FANPO：トップ' },
    },
    {
        path: '/login',
        name: 'login',
        meta: { title: 'FANPO：ログイン' },
        component: () => import('../views/login.vue'),
        beforeEnter: (to, from, next) => {
            firebase.auth().onAuthStateChanged((user) => {
                if (user) {
                    if (user.emailVerified) {
                        db.collection('users_private')
                            .doc(user.uid)
                            .set(
                                {
                                    email: user.email,
                                },
                                { merge: true }
                            )
                            .then(() => {
                                next({ path: 'mypage' });
                            });
                    } else {
                        db.collection('users_private')
                            .doc(user.uid)
                            .get()
                            .then((doc) => {
                                const email = doc.data()?.email;
                                if (email != user.email) {
                                    next({ path: 'change_mail_success' });
                                } else {
                                    next({ path: 'send_mail' });
                                }
                            });
                    }
                } else {
                    next();
                }
            });
        },
    },
    {
        path: '/select_use',
        name: 'select_use',
        component: () => import('../views/select_use.vue'),
        beforeEnter: (to, from, next) => {
            firebase.auth().onAuthStateChanged(async (user) => {
                if (user) {
                    await db
                        .collection('users_public')
                        .doc(user.uid)
                        .get()
                        .then((doc) => {
                            console.log('ドキュメントの有無', doc.data());
                            if (doc.data() == undefined) {
                                next();
                            } else {
                                next({ path: 'request_error' });
                            }
                        });
                } else {
                    next({ path: 'request_error' });
                }
            });
        },
    },
    {
        path: '/signup',
        name: 'signup',
        meta: { title: 'FANPO：新規登録' },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/signup.vue'),
    },
    {
        path: '/unauthenticated',
        name: 'unauthenticated',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/unauthenticated.vue'),
    },
    //{
    // path: '/select_use',
    // name: 'select_use',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    // component: () => import(/* webpackChunkName: "about" */ '../views/select_use.vue'),
    //},
    {
        path: '/send_mail',
        name: 'send_mail',
        meta: { title: 'FANPO：メール送信' },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/send_mail.vue'),
    },
    {
        path: '/success',
        name: 'success',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/success.vue'),
    },
    {
        path: '/user',
        name: 'user',
        component: () => import(/* webpackChunkName: "about" */ '../views/user.vue'),
    },
    {
        path: '/mypage',
        name: 'mypage',
        meta: { title: 'FANPO：設定' },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/mypage.vue'),
    },
    {
        path: '/account_setting_photo',
        name: 'account_setting_photo',
        meta: { title: 'FANPO：プロフィール写真' },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/account_setting_photo.vue'),
    },
    {
        path: '/account_setting_name',
        name: 'account_setting_name',
        meta: { title: 'FANPO：ニックネーム' },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/account_setting_name.vue'),
    },
    {
        path: '/account_setting_introduction',
        name: 'account_setting_introduction',
        meta: { title: 'FANPO：紹介文' },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/account_setting_introduction.vue'),
    },
    {
        path: '/account_setting_id',
        name: 'account_setting_id',
        meta: { title: 'FANPO：ユーザーID' },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/account_setting_id.vue'),
    },
    {
        path: '/account_setting_sex',
        name: 'account_setting_sex',
        meta: { title: 'FANPO：性別' },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/account_setting_sex.vue'),
    },
    {
        path: '/account_setting_single_word',
        name: 'account_setting_single_word',
        meta: { title: 'FANPO：ひとこと' },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/account_setting_single_word.vue'),
    },
    {
        path: '/user_link_setting',
        name: 'user_link_setting',
        meta: { title: 'FANPO：リンク設定' },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/user_link_setting.vue'),
    },
    {
        path: '/user_link_add',
        name: 'user_link_add',
        meta: { title: 'FANPO：リンク追加' },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/user_link_add.vue'),
    },
    {
        path: '/setting_style',
        name: 'setting_style',
        meta: { title: 'FANPO：デザイン編集' },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/setting_style.vue'),
    },
    {
        path: '/user_preview',
        name: 'user_preview',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/user_preview.vue'),
    },
    {
        path: '/account_setting_twitter_id',
        name: 'account_setting_twitter_id',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/account_setting_twitter_id.vue'),
    },
    {
        path: '/transfer_status',
        name: 'transfer_status',
        meta: { title: 'FANPO：振込情報' },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/transfer_status.vue'),
    },
    {
        path: '/setting_transfer_personal_information',
        name: 'setting_transfer_personal_information',
        meta: { title: 'FANPO：振込申請者' },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/setting_transfer_personal_information.vue'),
    },
    {
        path: '/request_error',
        name: 'request_error',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/request_error.vue'),
    },
    {
        path: '/setting_transfer_verification',
        name: 'setting_transfer_verification',
        meta: { title: 'FANPO：本人確認' },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/setting_transfer_verification.vue'),
    },
    {
        path: '/welcome',
        name: 'welcome',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/welcome.vue'),
    },
    {
        path: '/select_theme',
        name: 'select_theme',
        meta: { title: 'FANPO：テーマ選択' },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/select_theme.vue'),
    },
    {
        path: '/setting_back',
        name: 'setting_back',
        meta: { title: 'FANPO：背景選択' },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/setting_back.vue'),
    },
    {
        path: '/setting_button',
        name: 'setting_button',
        meta: { title: 'FANPO：リンクデザイン' },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/setting_button.vue'),
    },
    {
        path: '/setting_font',
        name: 'setting_font',
        meta: { title: 'FANPO：フォント設定' },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/setting_font.vue'),
    },
    {
        path: '/delete_fan_badge',
        name: 'delete_fan_badge',
        meta: { title: 'FANPO：ファンバッジ解約' },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/delete_fan_badge.vue'),
    },
    {
        path: '/support_report_view',
        name: 'support_report_view',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/support_report_view.vue'),
    },
    {
        path: '/support_report_delete',
        name: 'support_report_delete',
        meta: { title: 'FANPO：応援情報削除' },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/support_report_delete.vue'),
    },
    {
        path: '/privacy_settings',
        name: 'privacy_settings',
        meta: { title: 'FANPO：公開情報' },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/privacy_settings.vue'),
    },
    {
        path: '/point_historys',
        name: 'point_historys',
        meta: { title: 'FANPO：ポイント履歴' },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/point_historys.vue'),
    },
    {
        path: '/notifications',
        name: 'notifications',
        meta: { title: 'FANPO：通知一覧' },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/notifications.vue'),
    },
    {
        path: '/notice',
        name: 'notice',
        meta: { title: 'FANPO：投稿' },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/notice.vue'),
    },
    {
        path: '/notice_list',
        name: 'notice_list',
        meta: { title: 'FANPO：投稿一覧' },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/notice_list.vue'),
    },
    {
        path: '/mail_notice_setting',
        name: 'mail_notice_setting',
        meta: { title: 'FANPO：メール通知設定' },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/mail_notice_setting.vue'),
    },
    {
        path: '/setting_profile',
        name: 'setting_profile',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/setting_profile.vue'),
    },
    {
        path: '/settings',
        name: 'settings',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/settings.vue'),
    },

    {
        path: '/update',
        name: 'update',
        meta: { title: 'FANPO：アップデート' },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/update.vue'),
    },
    {
        path: '/my_ranking',
        name: 'my_ranking',
        meta: { title: 'FANPO：ランキング' },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/my_ranking.vue'),
    },
    {
        path: '/send_ranking',
        name: 'send_ranking',
        meta: { title: 'FANPO：応援ランキング' },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/send_ranking.vue'),
    },
    {
        path: '/follow',
        name: 'follow',
        meta: { title: 'FANPO：応援' },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/follow.vue'),
    },
    {
        path: '/follower',
        name: 'follower',
        meta: { title: 'FANPO：応援者' },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/follower.vue'),
    },
    {
        path: '/account_setting_birthday',
        name: 'account_setting_birthday',
        meta: { title: 'FANPO：生年月日' },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/account_setting_birthday.vue'),
    },
    {
        path: '/block_users',
        name: 'block_users',
        meta: { title: 'FANPO：ブロックユーザー' },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/block_users.vue'),
    },
    {
        path: '/setting_send_point',
        name: 'setting_send_point',
        meta: { title: '送信ポイント設定' },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/setting_send_point.vue'),
    },

    //ここまでは確実に使用する

    {
        path: '/setting_point_multiplier',
        name: 'setting_point_multiplier',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/setting_point_multiplier.vue'),
    },
    //{
    //path: '/sales_report',
    //name: 'sales_report',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    //component: () => import(/* webpackChunkName: "about" */ '../views/sales_report.vue'),
    //},
    {
        path: '/payment_setting',
        name: 'payment_setting',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/payment_setting.vue'),
    },
    {
        path: '/ranking/',
        name: 'ranking',
        meta: { title: 'FANPO：ランキング' },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/ranking.vue'),
    },
    {
        path: '/user_delete',
        name: 'user_delete',
        meta: { title: 'FANPO：ユーザー削除' },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/user_delete.vue'),
    },
    {
        path: '/user_delete_success',
        name: 'user_delete_success',
        meta: { title: 'FANPO：ユーザー削除完了' },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/user_delete_success.vue'),
    },
    {
        path: '/buy_point',
        name: 'buy_point',
        meta: { title: 'FANPO：ポイント購入' },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/buy_point.vue'),
    },
    {
        path: '/buy_rank',
        name: 'buy_rank',
        meta: { title: 'FANPO：バッジ購入' },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/buy_rank.vue'),
    },
    {
        path: '/success_buy_point',
        name: 'success_buy_point',
        meta: { title: 'FANPO：ポイント購入完了' },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/success_buy_point.vue'),
    },
    {
        path: '/send_point',
        name: 'send_point',
        meta: { title: 'FANPO：ポイント送信' },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/send_point.vue'),
    },
    {
        path: '/tokushoho',
        name: 'tokushoho',
        meta: { title: 'FANPO：特定商取引法に基づく表示' },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/tokushoho.vue'),
    },
    {
        path: '/kiyaku',
        name: 'kiyaku',
        meta: { title: 'FANPO：FANPO利用規約' },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/kiyaku.vue'),
    },
    {
        path: '/privacy',
        name: 'privacy',
        meta: { title: 'FANPO：プライバシーポリシー' },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/privacy.vue'),
    },
    {
        path: '/success_send_point/:id/:document_id',
        name: 'success_send_point',
        meta: { title: 'FANPO：ポイント送信完了' },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/success_send_point.vue'),
    },
    {
        path: '/success_ranking/:id/:fan_id',
        name: 'success_ranking',
        meta: { title: 'FANPO：応援情報' },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/success_ranking.vue'),
    },
    {
        path: '/success_buy_rank/:id/:document_id',
        name: 'success_buy_rank',
        meta: { title: 'FANPO：バッジ購入完了' },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/success_buy_rank.vue'),
    },
    {
        path: '/setting_transfer',
        name: 'setting_transfer',
        meta: { title: 'FANPO：振込設定' },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/setting_transfer.vue'),
    },
    {
        path: '/setting_transfer_test',
        name: 'setting_transfer_test',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/setting_transfer_test.vue'),
    },
    {
        path: '/fan_page',
        name: 'fan_page',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/no_use/fan_page.vue'),
    },
    {
        path: '/search',
        name: 'search',
        meta: { title: 'FANPO：検索' },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/search.vue'),
    },
    {
        path: '/fanclub_search_admin',
        name: 'fanclub_search_admin',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/no_use/fanclub_search_admin.vue'),
    },
    {
        path: '/setting_transfer_admin',
        name: 'setting_transfer_admin',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/setting_transfer_admin.vue'),
    },
    {
        path: '/buy_historys',
        name: 'buy_historys',
        meta: { title: 'FANPO：購入履歴' },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/buy_historys.vue'),
    },
    {
        path: '/follow_info_setting',
        name: 'follow_info_setting',
        meta: { title: 'FANPO：応援情報設定' },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/follow_info_setting.vue'),
    },
    {
        path: '/change_mail_success',
        name: 'change_mail_success',
        meta: { title: 'FANPO：メール更新' },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/change_mail_success.vue'),
    },
    {
        path: '/change_email',
        name: 'change_email',
        meta: { title: 'FANPO：メール更新' },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/change_email.vue'),
    },
    {
        path: '/change_password',
        name: 'change_password',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/change_password.vue'),
    },
    {
        path: '/change_password_success',
        name: 'change_password_success',
        meta: { title: 'FANPO：パスワード変更' },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/change_password_success.vue'),
    },
    {
        path: '/rank_payment',
        name: 'rank_payment',
        meta: { title: 'FANPO：決済ページ' },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/rank_payment.vue'),
    },
    {
        path: '/succsess_withdrawal',
        name: 'succsess_withdrawal',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/succsess_withdrawal.vue'),
    },
    {
        path: '/withdrawal',
        name: 'withdrawal',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/withdrawal.vue'),
    },
    {
        path: '/sales_confirmation',
        name: 'sales_confirmation',
        meta: { title: 'FANPO：振込申請' },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/sales_confirmation.vue'),
    },
    {
        path: '/signup_admin',
        name: 'signup_admin',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/signup_admin.vue'),
    },
    {
        path: '/user_search_admin',
        name: 'user_search_admin',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/user_search.vue'),
    },
    {
        path: '/usersetting_admin',
        name: 'usersetting_admin',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/usersetting_admin.vue'),
    },
    {
        path: '/application_user_list',
        name: 'application_user_list',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/application_user_list.vue'),
    },
    {
        path: '/application_success',
        name: 'application_success',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/application_success.vue'),
    },
    {
        path: '/send_change_password_mail',
        name: 'send_change_password_mail',
        meta: { title: 'FANPO：パスワード変更メール送信' },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/send_change_password_mail.vue'),
    },
    {
        path: '/send_reset_password_mail',
        name: 'send_reset_password_mail',
        meta: { title: 'FANPO：パスワードリセットメール送信' },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/send_reset_password_mail.vue'),
    },
    {
        path: '/reset_password',
        name: 'reset_password',
        meta: { title: 'FANPO：パスワードリセット' },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/reset_password.vue'),
    },
    {
        path: '/fp_payment',
        name: 'fp_payment',
        meta: { title: 'FANPO：ポイント購入' },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/fp_payment.vue'),
    },
    {
        path: '/setting_badge_point',
        name: 'setting_badge_point',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/setting_badge_point.vue'),
    },
    {
        path: '/preview',
        name: 'preview',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/preview.vue'),
    },
    {
        path: '/start_fanpo',
        name: 'start_fanpo',
        meta: { title: 'FANPO：ようこそ！' },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/start_fanpo.vue'),
    },
    {
        path: '/step1',
        name: 'step1',
        meta: { title: 'FANPO：Step1' },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/step1.vue'),
    },
    {
        path: '/step2',
        name: 'step2',
        meta: { title: 'FANPO：Step2' },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/step2.vue'),
    },
    {
        path: '/step3',
        name: 'step3',
        meta: { title: 'FANPO：Step3' },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/step3.vue'),
    },
    {
        path: '/step3_user_link_add',
        name: 'step3_user_link_add',
        meta: { title: 'FANPO：リンク追加' },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/step3_user_link_add.vue'),
    },
    {
        path: '/step4',
        name: 'step4',
        meta: { title: 'FANPO：Step4' },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/step4.vue'),
    },
    {
        path: '/step5',
        name: 'step5',
        meta: { title: 'FANPO：Step5' },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/step5.vue'),
    },
    {
        path: '/get_star_historys',
        name: 'get_star_historys',
        meta: { title: 'FANPO：スター獲得履歴' },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/get_star_historys.vue'),
    },
    {
        path: '/send_star_historys',
        name: 'send_star_historys',
        meta: { title: 'FANPO：スター送信履歴' },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/send_star_historys.vue'),
    },
    {
        path: '/_success_ranking/:id/:fan_id',
        redirect: { name: 'success_ranking' },
    },
    {
        path: '/_success_send_point/:id/:document_id',
        redirect: { name: 'success_send_point' },
    },
    {
        path: '/_success_buy_rank/:id/:document_id',
        redirect: { name: 'success_buy_rank' },
    },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

/*router.beforeEach((to, from, next) => {
    firebase.auth().onAuthStateChanged((user) => {
        if (user) {
            // 現在の日付情報を取得
            if (user.emailVerified) {
                const after_hour = dayjs(Cookies.get('last_time')).add(1, 'h').format();
                console.log(after_hour);
                if (Cookies.get('login_check') == 'false') {
                    if (dayjs().isAfter(after_hour)) {
                        firebase
                            .auth()
                            .signOut()
                            .then(() => {});
                        next({ path: 'login' });
                    } else {
                        Cookies.set('last_time', dayjs().format());
                        next();
                    }
                } else {
                    next();
                }
            } else {
                next();
            }
        } else {
            next();
        }
    });
});*/

export default router;
